<template>
  <div class="list-page">
    <div class="wrap">
      <div class="search-t">共找到<span>“{{this.$route.query.keywords}}”</span>相关结果<span>{{rowTotal}}</span>条</div>
      <div class="course-list z-flex">
        <courseItem
          v-for="(item,index) in rowList"
          :key="index"
          :itemData = item
        >
        </courseItem>
      </div>
      <div class="page-wrap" v-if="rowTotal">
        <el-pagination
          layout="prev, pager, next"
          :total="rowTotal"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
      <div class="noneImg" v-else>
        <img src="../assets/images/none.png">
      </div>
    </div>
  </div>
</template>

<script>
import courseItem from "../components/courseItem"
export default {
  components: {
    courseItem,
  },
  data() {
    return {
      currentPage:1,
      pageSize: 20, //每页20条
      rowTotal: 0,  //总数
      rowList: [], //列表
    };
  },
  mounted() {
    this.getRows();
  },
  created() {

  },
  methods: {
    //分页
    handleCurrentChange(currentPage){
      this.currentPage = currentPage;
      this.getRows();
    },
    //获取数据
    getRows(){
      let params = {
        page: this.currentPage,
        page_size: this.pageSize,
        keywords: this.$route.query.keywords
      };
      var that = this;
      this.$axios.api.search(params, function (res) {
        if (res.code == 0) {
          that.rowTotal = res.data.total;
          that.rowList = res.data.list;
        } else {
          console.log(res.msg);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.page-t{
  font-size: 25px;
}
.search-t{
  font-size: 18px;
  margin-top: 30px;
  span{
    color: $colorMain;
    margin: 0 10px;
  }
}
.course-list{
  margin: 30px 0 20px;
  .course-item{
    margin: 0 20px 20px 0;
    &:nth-child(4n){
      margin: 0 0 20px 0;
    }
  }
}
.noneImg{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;

    img{
      height: 350px;
    }
  }
</style>
